export const GET_USUARIO = "GET_USUARIO";
export const GET_EVENTOS = "GET_EVENTOS";
export const SET_EVENTO = "SET_EVENTO";
export const GET_EXPEDIENTE = "GET_EXPEDIENTE";
export const GET_PACIENTES = "GET_PACIENTES";
export const GET_PACIENTE = "GET_PACIENTE";
export const GET_SESIONES = "GET_SESIONES";
export const SET_USUARIO = "SET_USUARIO";
export const ADD_USUARIO = "ADD_USUARIO";
export const SET_RESADD = "SET_RESADD";
export const EDIT_USUARIO = "EDIT_USUARIO";
export const ADD_DIAGNOSTICO = "ADD_DIAGNOSTICO";
export const EDIT_DIAGNOSTICO = "EDIT_DIAGNOSTICO";
export const PERFIL_USUARIO = "PERFIL_USUARIO";
export const APERFIL_USUARIO = "APERFIL_USUARIO";
export const EPERFIL_USUARIO = "EPERFIL_USUARIO";
export const GET_TERAPIAS = "GET_TERAPIAS";
export const GET_APARATOS = "GET_APARATOS";
export const SET_PACIENTE_SELECCIONADO = "SET_PACIENTE_SELECCIONADO";
export const SET_DIAGNOSTICO_SELECCIONADO = "SET_DIAGNOSTICO_SELECCIONADO";
export const SET_SESION = "SET_SESION";
export const GET_TERAPEUTAS = "GET_TERAPEUTAS";
export const GET_TERAPEUTA = "GET_TERAPEUTA";
export const SET_TERAPIA = "SET_TERAPIA";
