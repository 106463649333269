import { faAdd, faFilePen, faNoteSticky, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import UsuarioContext from "../context/usuarios/UsuarioContext";
import {
  Badge,
  Button,
  Col,
  DropdownButton,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import dayjs from "dayjs";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const Sesiones = () => {
  const {
    getListaTerapias,
    ListaTerapias,
    getListaAparatos,
    ListaAparatos,
    PacienteSeleccionado,
    DiagnosticoSeleccionado,
    getSesiones,
    sesiones,
    AgregarSesion,
    resaddsesion
  } = useContext(UsuarioContext);
  const [show, setshow] = useState(false);
  const now = dayjs().format("YYYY-MM-DD");
  const aparatos = makeAnimated();
  const [nterapia, setnterapia] = useState({
    paciente: "",
    diagnostico: "",
    terapia: "",
    sesiones: 0,
    sesiones_tomadas: 0,
    aparatos: "",
    costo: 0,
    fecha_inicio: "",
    fecha_fin: "",
    nota:""
  });

  const CambioTerapiaId = (e) => {
    console.log(e.target.value)
    ListaTerapias.forEach((tera) => {
      if (tera.id === e.target.value) {
        setnterapia({
          ...nterapia,
          terapia: tera.id,
          sesiones:tera.sesiones,
          aparatos: tera.aparato,
          costo: tera.precio,
        });
      }
    });
  };



  const CambioTerapia = (e) => {
    setnterapia({ ...nterapia, [e.target.name]: e.target.value });
  };
  const CambioTerapiaAparatos = (lista) => {
    setnterapia({ ...nterapia, aparatos: lista });
  };
  const BorrarSesion = (id) => {
    console.log(id);
  };
  const AgregarNuevaSesion = () => {
   AgregarSesion(nterapia)
  };

  React.useEffect(() => {
    getListaTerapias();
    getListaAparatos();
  }, []);

  React.useEffect(() => {
    if(PacienteSeleccionado ==0 || DiagnosticoSeleccionado== 0){
      getSesiones(PacienteSeleccionado, DiagnosticoSeleccionado);
    }else{
       getSesiones(PacienteSeleccionado, DiagnosticoSeleccionado);
    setnterapia({
      ...nterapia,
      paciente: PacienteSeleccionado,
      diagnostico: DiagnosticoSeleccionado,  });
    }
   
  
  }, [DiagnosticoSeleccionado, PacienteSeleccionado]);

  React.useEffect(() => {
    getSesiones(PacienteSeleccionado, DiagnosticoSeleccionado);
    setshow(false)
  }, [resaddsesion]);



  return (
    <div>
      <Row>
        <Col md={4}>
          <div className="text-muted fs-4 text-center">Terapias</div>
        </Col>
        <Col md={4}></Col>
        <Col md={4}>
        {PacienteSeleccionado >0  &&DiagnosticoSeleccionado >0 ? (
            <div className="fw-bold fs-4 text-center float-end"  >
            <Button
              className="btn btn-primary fw-bold fs-5 text-center"
              onClick={() => setshow(true)}
            >
              <FontAwesomeIcon icon={faAdd} />
            </Button>
          </div> 
          ) : (
            <div className="fw-bold fs-4 text-center float-end"  >
          </div>
          )}


   
        </Col>
      </Row>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Terapia</th>
            <th>Sesiones</th>
            <th>Tomadas</th>
            <th>Aparatos</th>
            <th>Inicia</th>
            <th>Finaliza</th>
            <th>Costo</th>
            <th>Nota</th>
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {sesiones.map((elemento) => {
            return (
              <tr key={elemento.id} className="fs-6 fw-bolder">
                <td> {elemento.terapia}</td>
                <td> {elemento.sesiones}</td>
                <td> {elemento.sesiones_tomadas}</td>
                <td> 
 { JSON.parse(elemento.aparatos).map((apa)=>{
   return <Badge key={apa.value} bg="primary">{apa.label}</Badge>
    } )}</td>
                <td> {elemento.fecha_inicio}</td>
                <td> {elemento.fecha_fin}</td>
                <td> {elemento.costo}</td>
                <td>   <DropdownButton id="dropdown-basic-button" title={<FontAwesomeIcon icon={faNoteSticky}/>}>
                        <div className="p-1">{elemento.nota}</div>
                       </DropdownButton>
                  
                  
                 </td>
                <td>
                  {" "}
                  <Button
                    onClick={() => {
                      BorrarSesion(elemento.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Modal size="lg" show={show} onHide={() => setshow(false)} centered>
        <Modal.Header
          className="bg-primary fw-bolder fs-6 text-white"
          closeButton
        >
          <Modal.Title>Agregar Terapia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Terapia</Form.Label>
              <Form.Select
                size="md"
                name="terapia"
                onChange={CambioTerapiaId}
                value={nterapia.terapia}
              >
                <option key={0} value={0}>Selecciona Terapia</option>
                {ListaTerapias.map((elemento) => {
                  return <option  key={elemento.id} value={elemento.id}>{elemento.nombre}</option>;
                })}
              </Form.Select>
            </Col>
            <Col md={2}>
              <Form.Label className="fs-6 fw-bold">No Sesiones</Form.Label>
              <Form.Control
                size="md"
                type="number"
                name="sesiones"
                min={1}
                max={30}
                onChange={CambioTerapia}
                value={nterapia.sesiones}
                readOnly
              />
            </Col>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Aparatos</Form.Label>
              <Form.Control
                size="md"
                type="text"
                name="aparatos"
                onChange={CambioTerapia}
                value={nterapia.aparatos}
                readOnly
              />
            </Col>
            <Col md={2}>
              <Form.Label className="fs-6 fw-bold">Costo</Form.Label>
              <Form.Control
                value={nterapia.costo}
                size="md"
                type="number"
                name="costo"
                min={0}
                onChange={CambioTerapia}
                readOnly
              />
            </Col>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Sesiones Tomadas</Form.Label>
              <Form.Control
                size="md"
                type="number"
                name="sesiones_tomadas"
                value={nterapia.sesiones_tomadas}
                min={0}
                max={30}
                onChange={CambioTerapia}
              />
            </Col>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Fecha Inicial</Form.Label>
              <Form.Control
                size="md"
                type="date"
                name="fecha_inicio"
                min={now}
                onChange={CambioTerapia}
                value={nterapia.fecha_inicio}
              />
            </Col>
            <Col md={4}>
              <Form.Label className="fs-6 fw-bold">Fecha Final</Form.Label>
              <Form.Control
                size="md"
                type="date"
                name="fecha_fin"
                min={now}
                onChange={CambioTerapia}
                value={nterapia.fecha_fin}
              />
            </Col>
            
           
            <Col md={12}>
              <Form.Label className="fs-6 fw-bold">Nota</Form.Label>
              <Form.Control
                as="textarea" rows={3} 
                value={nterapia.nota}
                size="md"
                type="text"
                name="nota"
                onChange={CambioTerapia}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setshow(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={AgregarNuevaSesion}>
            <FontAwesomeIcon icon={faFilePen} /> Agregar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sesiones;
